import { createContext, useContext } from "react";

// Constants
const SET_NOTIFICATION_SATUS = "SET_NOTIFICATION_SATUS";
const SET_NATIONAL_NOTIFICATION_SATUS = "SET_NATIONAL_NOTIFICATION_SATUS";
const SET_MARKET_NOTIFICATION_SATUS = "SET_MARKET_NOTIFICATION_SATUS";
const OPEN_REGISTRATION_MODAL = "OPEN_REGISTRATION_MODAL";
const CLOSE_REGISTRATION_MODAL = "CLOSE_REGISTRATION_MODAL";
const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
const CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL";
const OPEN_SUCCESS_MODAL_PROMO = "OPEN_SUCCESS_MODAL_PROMO";
const CLOSE_SUCCESS_MODAL_PROMO = "CLOSE_SUCCESS_MODAL_PROMO";
const OPEN_SUCCESS_MODAL_INVESTORS = "OPEN_SUCCESS_MODAL_INVESTORS";
const CLOSE_SUCCESS_MODAL_INVESTORS = "CLOSE_SUCCESS_MODAL_INVESTORS";
const OPEN_SUCCESS_MODAL_WARRANTY = "OPEN_SUCCESS_MODAL_WARRANTY";
const CLOSE_SUCCESS_MODAL_WARRANTY = "CLOSE_SUCCESS_MODAL_WARRANTY";
const OPEN_GALLERY_MODAL = "OPEN_GALLERY_MODAL";
const CLOSE_GALLERY_MODAL = "CLOSE_GALLERY_MODAL";
const UPDATE_FAVORITES = "UPDATE_FAVORITES";
const UPDATE_MARKET_COMMUNITIES_FORM = "UPDATE_MARKET_COMMUNITIES_FORM";
const UPDATE_SHOW_MARKET_COMMUNITIES_FORM =
  "UPDATE_SHOW_MARKET_COMMUNITIES_FORM";
const UPDATE_CONDO_COLOR_HEXA_CODE = "UPDATE_CONDO_COLOR_HEXA_CODE";
const UPDATE_CONDO_TEXT_COLOR_HEXA_CODE = "UPDATE_CONDO_TEXT_COLOR_HEXA_CODE";
const UPDATE_SHOW_CONDO_COLOR_HEXA_CODE = "UPDATE_SHOW_CONDO_COLOR_HEXA_CODE";
const SET_HAS_COMMING_SOON_COMMUNITY = "SET_HAS_COMMING_SOON_COMMUNITY";
const UPDATE_DATA_FORM_BLOCK_ID = "UPDATE_DATA_FORM_BLOCK_ID";
const UPDATE_FORM_DATA_SCRIPT_SRC = "UPDATE_FORM_DATA_SCRIPT_SRC";
const UPDATE_FORM_DATA_TRACKING_SCRIPT_SRC =
  "UPDATE_FORM_DATA_TRACKING_SCRIPT_SRC";
const UPDATE_TRACKING_DIV = "UPDATE_TRACKING_DIV";
const UPDATE_FORM_DATA_DIV = "UPDATE_FORM_DATA_DIV";
const UPDATE_DYNAMICS_DIV = "UPDATE_DYNAMICS_DIV";
const UPDATE_LOG_LIST = "UPDATE_LOG_LIST";
const UPDATE_ATLAS_RTX = "UPDATE_ATLAS_RTX";
const UPDATE_DYNAMX_FORM_ID = "UPDATE_DYNAMX_FORM_ID";
const UPDATE_DYNAMX_FORM_API_URL = "UPDATE_DYNAMX_FORM_API_URL";
// Context
export const UiContext = createContext({});

// Initial State
export const initialState = {
  notificationActive: false,
  nationalNotificationActive: false,
  marketNotificationActive: false,
  showRegistrationModal: false,
  showSuccessModal: false,
  showSuccessModalPromo: false,
  showSuccessModalInvestors: false,
  showSuccessModalWarranty: false,
  showGalleryModal: false,
  favoritesList: [],
  marketCommunitiesForm: [],
  showMarketCommunitiesForm: false,
  newSchemaColorHexaCode: "",
  newSchemaTextColorHexaCode: "",
  showNewSchemaColor: false,
  hasCommingSoonCommunity: false,
  dataFormBlockId: "",
  formDataScriptSrc: "",
  formDataTrackingScriptSrc: "",
  trackingDiv: "",
  formDataDiv: "",
  dynamicsDiv: "",
  logList: [],
  atlasRTXChatbotScript: "",
  dynaMXFormID: "",
  dynaMXFormAPIURL: "",
};

// Reducer
export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_SATUS:
      return {
        ...state,
        notificationActive: action.payload,
      };
    case SET_NATIONAL_NOTIFICATION_SATUS:
      return {
        ...state,
        nationalNotificationActive: action.payload,
      };
    case SET_MARKET_NOTIFICATION_SATUS:
      return {
        ...state,
        marketNotificationActive: action.payload,
      };
    case SET_HAS_COMMING_SOON_COMMUNITY:
      return {
        ...state,
        hasCommingSoonCommunity: action.payload,
      };
    case OPEN_REGISTRATION_MODAL:
      return {
        ...state,
        showRegistrationModal: true,
      };
    case CLOSE_REGISTRATION_MODAL:
      return {
        ...state,
        showRegistrationModal: false,
      };
    case OPEN_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessModal: true,
      };
    case CLOSE_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessModal: false,
      };
    case OPEN_SUCCESS_MODAL_PROMO:
      return {
        ...state,
        showSuccessModalPromo: true,
      };
    case CLOSE_SUCCESS_MODAL_PROMO:
      return {
        ...state,
        showSuccessModalPromo: false,
      };
    case OPEN_SUCCESS_MODAL_INVESTORS:
      return {
        ...state,
        showSuccessModalInvestors: true,
      };
    case CLOSE_SUCCESS_MODAL_INVESTORS:
      return {
        ...state,
        showSuccessModalInvestors: false,
      };
    case OPEN_SUCCESS_MODAL_WARRANTY:
      return {
        ...state,
        showSuccessModalWarranty: true,
      };
    case CLOSE_SUCCESS_MODAL_WARRANTY:
      return {
        ...state,
        showSuccessModalWarranty: false,
      };
    case OPEN_GALLERY_MODAL:
      return {
        ...state,
        showGalleryModal: true,
      };
    case CLOSE_GALLERY_MODAL:
      return {
        ...state,
        showGalleryModal: false,
      };
    case UPDATE_FAVORITES:
      return {
        ...state,
        favoritesList: action.payload,
      };
    case UPDATE_MARKET_COMMUNITIES_FORM:
      return {
        ...state,
        marketCommunitiesForm: action.payload,
      };
    case UPDATE_SHOW_MARKET_COMMUNITIES_FORM:
      return {
        ...state,
        showMarketCommunitiesForm: action.payload,
      };
    case UPDATE_CONDO_COLOR_HEXA_CODE:
      return {
        ...state,
        newSchemaColorHexaCode: action.payload,
      };
    case UPDATE_CONDO_TEXT_COLOR_HEXA_CODE:
      return {
        ...state,
        newSchemaTextColorHexaCode: action.payload,
      };
    case UPDATE_SHOW_CONDO_COLOR_HEXA_CODE:
      return {
        ...state,
        showNewSchemaColor: action.payload,
      };
    case UPDATE_DATA_FORM_BLOCK_ID:
      return {
        ...state,
        dataFormBlockId: action.payload,
      };
    case UPDATE_FORM_DATA_SCRIPT_SRC:
      return {
        ...state,
        formDataScriptSrc: action.payload,
      };
    case UPDATE_FORM_DATA_TRACKING_SCRIPT_SRC:
      return {
        ...state,
        formDataTrackingScriptSrc: action.payload,
      };
    case UPDATE_TRACKING_DIV:
      return {
        ...state,
        trackingDiv: action.payload,
      };
    case UPDATE_FORM_DATA_DIV:
      return {
        ...state,
        formDataDiv: action.payload,
      };
    case UPDATE_DYNAMICS_DIV:
      return {
        ...state,
        dynamicsDiv: action.payload,
      };
    case UPDATE_LOG_LIST:
      return {
        ...state,
        logList: action.payload,
      };
    case UPDATE_ATLAS_RTX:
      return {
        ...state,
        atlasRTXChatbotScript: action.payload,
      };
    case UPDATE_DYNAMX_FORM_ID:
      return {
        ...state,
        dynaMXFormID: action.payload,
      };
    case UPDATE_DYNAMX_FORM_API_URL:
      return {
        ...state,
        dynaMXFormAPIURL: action.payload,
      };

    default:
      throw new Error("[UiReducer] Invalid Action Type");
  }
};

// Actions
export const setNotificationStatus = (state) => ({
  type: SET_NOTIFICATION_SATUS,
  payload: state,
});
export const setNationalNotificationStatus = (state) => ({
  type: SET_NATIONAL_NOTIFICATION_SATUS,
  payload: state,
});
export const setMarketNotificationStatus = (state) => ({
  type: SET_MARKET_NOTIFICATION_SATUS,
  payload: state,
});
export const setHasCommingSonnCommunity = (state) => ({
  type: SET_HAS_COMMING_SOON_COMMUNITY,
  payload: state,
});
export const openRegistrationModal = () => ({
  type: OPEN_REGISTRATION_MODAL,
});
export const closeRegistrationModal = () => ({
  type: CLOSE_REGISTRATION_MODAL,
});
export const openSuccessModal = () => ({
  type: OPEN_SUCCESS_MODAL,
});
export const closeSuccessModal = () => ({
  type: CLOSE_SUCCESS_MODAL,
});
export const openSuccessModalPromo = () => ({
  type: OPEN_SUCCESS_MODAL_PROMO,
});
export const closeSuccessModalPromo = () => ({
  type: CLOSE_SUCCESS_MODAL_PROMO,
});
export const openSuccessModalInvestors = () => ({
  type: OPEN_SUCCESS_MODAL_INVESTORS,
});
export const closeSuccessModalInvestors = () => ({
  type: CLOSE_SUCCESS_MODAL_INVESTORS,
});
export const openSuccessModalWarranty = () => ({
  type: OPEN_SUCCESS_MODAL_WARRANTY,
});
export const closeSuccessModalWarranty = () => ({
  type: CLOSE_SUCCESS_MODAL_WARRANTY,
});
export const openGalleryModal = () => ({
  type: OPEN_GALLERY_MODAL,
});
export const closeGalleryModal = () => ({
  type: CLOSE_GALLERY_MODAL,
});
export const updateFavorites = (list) => ({
  type: UPDATE_FAVORITES,
  payload: list,
});
export const updateMarketCommunitiesForm = (list) => ({
  type: UPDATE_MARKET_COMMUNITIES_FORM,
  payload: list,
});
export const updateShowMarketCommunitiesForm = (flag) => ({
  type: UPDATE_SHOW_MARKET_COMMUNITIES_FORM,
  payload: flag,
});
export const updateNewSchemaColorHexaCode = (code) => ({
  type: UPDATE_CONDO_COLOR_HEXA_CODE,
  payload: code,
});
export const updateNewSchemaTextColorHexaCode = (code) => ({
  type: UPDATE_CONDO_TEXT_COLOR_HEXA_CODE,
  payload: code,
});
export const updateShowNewSchemaColorHexaCode = (flag) => ({
  type: UPDATE_SHOW_CONDO_COLOR_HEXA_CODE,
  payload: flag,
});
export const updateDataFormBlockIdContext = (id) => ({
  type: UPDATE_DATA_FORM_BLOCK_ID,
  payload: id,
});
export const updateFormDataScriptSrcContext = (src) => ({
  type: UPDATE_FORM_DATA_SCRIPT_SRC,
  payload: src,
});
export const updateFormDataTrackingScriptSrcContext = (src) => ({
  type: UPDATE_FORM_DATA_TRACKING_SCRIPT_SRC,
  payload: src,
});
export const updateTrackingDivContext = (src) => ({
  type: UPDATE_TRACKING_DIV,
  payload: src,
});
export const updateFormDataDivContext = (src) => ({
  type: UPDATE_FORM_DATA_DIV,
  payload: src,
});
export const updateDynamicsDivContext = (src) => ({
  type: UPDATE_DYNAMICS_DIV,
  payload: src,
});
export const updateLogList = (list) => ({
  type: UPDATE_LOG_LIST,
  payload: list,
});
export const updateAtlasRTXChatbotScriptContext = (script) => ({
  type: UPDATE_ATLAS_RTX,
  payload: script,
});
export const updateDynaMXFormIDContext = (id) => ({
  type: UPDATE_DYNAMX_FORM_ID,
  payload: id,
});
export const updateDynaMXFormAPIURLContext = (api) => ({
  type: UPDATE_DYNAMX_FORM_API_URL,
  payload: api,
});

// Hooks
export const useUiValue = () => useContext(UiContext);
